<template>
  <el-dialog
      :title='taskName + "任务日志"'
      :close-on-click-modal="false"
      :visible.sync="visible"
      v-if="visible"
      @close="closeDialog"
      width="75%">
    <el-button type="primary" @click="queryLog(taskId)">刷新日志</el-button>
    <el-tabs v-model="activeName">
      <el-tab-pane label="数据源捕获任务日志" name="first">
        <div class="logContent" v-loading="dataListLoading">
          {{ CDCLog }}
        </div>
      </el-tab-pane>
      <el-tab-pane label="数据治理任务日志" name="second">
        <div class="logContent">
          {{ DGLog }}
        </div>
      </el-tab-pane>
      <el-tab-pane label="数据存储任务日志" name="third">
        <div class="logContent">
          {{ DSLog }}
        </div>
      </el-tab-pane>
    </el-tabs>
  </el-dialog>
</template>

<script>
export default {
  name: "checkTaskLog",
  data() {
    return {
      activeName: 'first',
      CDCLog: "",
      DGLog: "",
      DSLog: "",
      visible: false,
      taskName: '',
      taskId: '',
      dataListLoading: false
    }
  },
  methods: {
    init (taskId, taskName) {
      this.visible = true
      this.taskId = taskId
      this.taskName = taskName
      this.queryLog(taskId)
    },
    queryLog(taskId) {
      this.CDCLog = ''
      this.DGLog = ''
      this.DSLog = ''
      this.dataListLoading = true
      this.getRequest("/taskLog/queryLog?taskId=" + taskId).then((resp) => {
        if (resp) {
          console.log(resp);
          this.CDCLog = resp.data.CDCLog
          this.DGLog = resp.data.DGLog
          this.DSLog = resp.data.DSLog
        }
      })
      this.dataListLoading = false
    },
    closeDialog() {
      this.visible = false;
    },
  }
}
</script>

<style scoped>
.logContent {
  white-space:pre-wrap;
}
</style>
