<template>
  <el-dialog :title="'新增数据接入任务'" :close-on-click-modal="false" :visible.sync="addTaskDialog">
  <el-form :model="dataForm" ref="dataForm"
           label-width="100px"
           :rules="rules">
    <el-form-item label="任务名称" prop="taskName">
      <el-input style="width: 45%;margin-bottom: 5px" v-model="dataForm.taskName" placeholder="任务名称"></el-input>
    </el-form-item>
    <el-form-item label="描述" prop="remark">
      <el-input style="width: 45%;margin-bottom: 5px" v-model="dataForm.remark" placeholder="描述" type="textarea"></el-input>
    </el-form-item>
    <el-form-item label="数据源类型" size="mini">
      <el-radio-group v-model="sourceType">
        <el-radio :label="1">数据库</el-radio>
        <el-radio :label="2">文件</el-radio>
        <el-radio :label="3">传感器</el-radio>
        <el-radio :label="4">第三方</el-radio>
        <el-radio :label="5">opc</el-radio>
      </el-radio-group>
    </el-form-item>
  </el-form>
  <span slot="footer" class="dialog-footer">
      <el-button @click="addTaskDialog = false">取消</el-button>
      <el-button type="primary" @click="createTaskPreHandle()">确定</el-button>
  </span>
</el-dialog>
</template>

<script>
export default {
  name: "addAccessTaskDialog",
  data() {
    return {
      addTaskDialog: false,
      rules: {
        taskName: [
          {required: true, message: '请输入任务名称', trigger: 'blur'}
        ],
        remark: [
          {required: true, message: '请输入任务描述', trigger: 'blur'}
        ],
      },
    //  新版
      dataForm: {},
      sourceType: 1

    }
  },
  mounted() {
  },
  created() {
    // this.addForm.currOperator = JSON.parse(
    //     window.sessionStorage.getItem("user")
    // ).userId;
    // this.addForm.currOperatorName = JSON.parse(
    //     window.sessionStorage.getItem("user")
    // ).username;
  },
  methods: {
    init() {
      this.addTaskDialog = true;
    },
    closeDialog() {
      this.addTaskDialog = false;
      this.$refs["addForm"].resetFields();
    },
    //选择任务类型：数据库任务/...
    createTaskPreHandle() {
      this.$refs["dataForm"].validate((isPass) => {
        if (isPass) {
          this.addTaskDialog = false
          this.$router.replace({ path: '/addDataAccessTask',
            query: {sourceType: this.sourceType, taskName: this.dataForm.taskName, remark: this.dataForm.remark}})
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
