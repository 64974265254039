<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb
        separator-class="el-icon-arrow-right"
        v-if="this.$router.currentRoute.path != '/home'"
    >
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>数据管理</el-breadcrumb-item>
      <el-breadcrumb-item>数据入湖管理</el-breadcrumb-item>
    </el-breadcrumb>
    <span style="font-size: 12px">虚拟入湖概述：</span>
    <div class="coalblock_1" v-for="item in options" :key="item.title">
      <span style="font-size: 12px; color: #9196a1">{{ item.title }}</span>
      <!-- <br /> -->
      <span style="font-size: 24px;margin-left: 10px;">{{ item.num }}</span>
    </div>
    <el-form :inline="true" class="demo-form-inline">
      <el-form-item>
        <el-button v-if="isAuth('dataAccess:add')" type="primary" @click="addDataAccessTask">新增虚拟入湖任务</el-button>
        <el-button v-if="isAuth('dataAccess:delete')" type="danger" @click="deleteTask()" :disabled="dataListSelections.length <= 0" v-show="setting.batchRemove">批量删除</el-button>
      </el-form-item>
      <span style="font-size: 16px">搜索：</span>
      <el-form-item>
        <el-input v-model="taskNameValue" placeholder="请输入要搜索的内容"></el-input>
      </el-form-item>
      <el-form-item>
        <el-select  v-if="setting.advancesearch" v-model="databaseValue" placeholder="数据库类型" style="width: 200px" clearable @change="queryByCondition()">
          <el-option
              v-for="item in databaseoptions"
              :key="item.value"
              :label="item.label"
              :value="item.label"
          >
          </el-option>
        </el-select>&nbsp;
        <!--          <el-select v-model="method" placeholder="方式" style="width: 200px">-->
        <!--            <el-option-->
        <!--              v-for="item in methodoptions"-->
        <!--              :key="item.value"-->
        <!--              :label="item.label"-->
        <!--              :value="item.value"-->
        <!--            >-->
        <!--            </el-option>-->
        <!--          </el-select>&nbsp;-->
        <el-select v-if="setting.advancesearch" v-model="status" placeholder="运行状态" style="width: 200px" clearable @change="queryByCondition()">
          <el-option
              v-for="item in statusoptions"
              :key="item.value"
              :label="item.label"
              :value="item.state"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
            type="primary"
            @click="queryByCondition()"
        >查询</el-button>
      </el-form-item>
      <!--        <el-form-item>-->
      <!--          <el-button>重置</el-button>-->
      <!--        </el-form-item>-->
    </el-form>
    <el-table
        :data="tableData"
        style="min-width: 100%"
        border
        v-loading="dataListLoading"
        @selection-change="selectionChangeHandle"
    >
      <el-table-column
       v-if="setting.batchRemove"
          type="selection"
          header-align="center"
          align="center"
          min-width="10%"
      >
      </el-table-column>
      <el-table-column
          label="序号"
          type="index"
          min-width="5%"
          align="center">
        <template slot-scope="scope">
          <span>{{(page - 1) * size + scope.$index + 1}}</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="id"
          label="虚拟入湖ID"
          header-align="center"
          align="center"
          min-width="5%"
          v-if=false
      >
      </el-table-column>
      <el-table-column
          prop="name"
          label="虚拟入湖名称"
          header-align="center"
          align="center"
          min-width="10%"
      >
      </el-table-column>
      <el-table-column
          prop="accessSource"
          label="接入源"
          header-align="center"
          align="center"
          min-width="10%"
      >
      </el-table-column>
      <el-table-column
          prop="remark"
          label="概述"
          header-align="center"
          align="center"
          min-width="10%"
      >
      </el-table-column>
      <el-table-column
          prop="status"
          label="状态"
          header-align="center"
          align="center"
          min-width="10%"
      >
        <template slot-scope="scope">
          <el-tag
              effect="plain"
              size="mini"
              v-if="scope.row.status == '待运行'"
          >未物理入湖</el-tag
          >
          <el-tag
              effect="plain"
              type="success"
              size="mini"
              v-else-if="scope.row.status == '运行中'"
          >物理入湖中</el-tag
          >
          <el-tag
              effect="plain"
              type="danger"
              size="mini"
              v-else-if="scope.row.status == '暂停'"
          >暂停入湖</el-tag
          >
          <el-tag
              effect="plain"
              type="info"
              size="mini"
              v-else-if="scope.row.status == '已完成'"
          >已完成</el-tag
          >
          <!--              <el-tag effect="plain" type="warning" size="mini" v-else>草稿</el-tag>-->
        </template>
      </el-table-column>

      <el-table-column
          label="操作"
          header-align="center"
          align="center"
          min-width="10%"
      >
        <template slot-scope="scope">
          <el-button
              v-if="isAuth('dataAccess:operation') && scope.row.status == '待运行'"
              type="text"
              size="small"
              @click="startTask(scope.row.id, scope.row.accessSource)"
          >进行物理入湖</el-button
          >
          <el-button
              v-else-if="isAuth('dataAccess:operation') && scope.row.status == '运行中'"
              type="text"
              size="small"
              @click="pauseTask(scope.row.id, scope.row.accessSource)"
          >暂停物理入湖</el-button
          >
          <!--              <el-button type="text" size="small">查看</el-button>-->
          <!--              <el-button type="text" size="small">复制</el-button>-->
          <el-button
              v-else-if="isAuth('dataAccess:operation') && scope.row.status == '暂停'"
              type="text"
              size="small"
              @click="recover(scope.row.id, scope.row.accessSource)"
          >重启物理入湖</el-button
          >
          <el-button
              v-if="isAuth('dataAccess:operation') && scope.row.status == '运行中'"
              type="text"
              size="small"
              style="color: red"
              @click="deleteJob(scope.row.id, scope.row.accessSource)"
          >终止物理入湖</el-button
          >
          <el-button
              v-if="isAuth('dataAccess:update') && scope.row.status == '待运行'"
              type="text"
              size="small"
              @click="updateJob(scope.row.id, scope.row.accessSource, scope.row.name, scope.row.remark)"
          >修改</el-button
          >
          <el-button
              v-if="isAuth('dataAccess:operation') &&(scope.row.accessSource == 'txt'||scope.row.accessSource == 'word'||scope.row.accessSource == 'excel')"
              type="text"
              size="small"
              @click="downLoadJob(scope.row.id, scope.row.accessSource)"
          >下载</el-button
          >
          <el-button
              type="text"
              size="small"
              @click="codeTemplate(scope.row.id, scope.row.accessSource)"
              v-if="scope.row.accessSource == '传感器'||scope.row.accessSource == '第三方'"
          >代码模板</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
          label="日志信息"
          header-align="center"
          align="center"
          min-width="10%"
      >
        <template slot-scope="scope">
          <el-button v-if="isAuth('sys:log:list')" type="success" @click="logHandle(scope.row.id, scope.row.name)">任务日志</el-button>

          <el-button v-if="isAuth('sys:log:list')" type="success" @click="taskLog(scope.row.id, scope.row.name)">查看日志</el-button>
        </template>
      </el-table-column>
    </el-table>

    <add-access-task-dialog
        ref="addAccessTaskDialog"
        v-if="addTaskDialog"
    ></add-access-task-dialog>
    <!-- 分页区域 -->
    <div style="display: flex; justify-content: flex-end">
      <el-pagination
          background
          @current-change="currentChange"
          @size-change="sizeChange"
          :current-page="page"
          :page-size="size"
          layout="sizes, prev, pager, next, jumper, ->, total, slot"
          :total="total"
      >
      </el-pagination>
    </div>
    <check-job-log v-if="logVisible" ref="checkJobLog"></check-job-log>
    <check-task-log v-if="taskLogVisible" ref="checkTaskLog"></check-task-log>
    <codeTemplate ref="codeTemplate"></codeTemplate>
  </div>
</template>

<script>
import addAccessTaskDialog from "./addAccessTaskDialog";
import checkJobLog from "./checkJobLog";
import codeTemplate from "./codeTemplate"
import CheckTaskLog from "./checkTaskLog";

export default {
  name: "dataAccessManage",
  data() {
    return {
      name:'数据接入管理',
      addTaskDialog: false,
      logVisible: false,
      taskLogVisible: false,
      options: [
        {
          title: "任务个数",
          num: "0",
        },
        {
          title: "未物理入湖任务",
          num: "0",
        },
        {
          title: "物理入湖中任务",
          num: "0",
        },
        {
          title: "暂停入湖任务",
          num: "0",
        },
        {
          title: "已完成任务",
          num: "0",
        },
      ],
      databaseoptions: [
        {
          value: "选项1",
          label: "mysql",
        },
        {
          value: "选项2",
          label: "influxdb",
        },
        {
          value: "选项3",
          label: "txt",
        },
        {
          value: "选项4",
          label: "word",
        },
        {
          value: "选项5",
          label: "excel",
        },{
          value: "选项6",
          label: "传感器",
        },{
          value: "选项7",
          label: "第三方",
        },{
          value: "选项8",
          label: "opc",
        },
      ],
      methodoptions: [
        {
          value: "选项1",
          label: "在线",
        },
        {
          value: "选项2",
          label: "离线",
        },
      ],
      statusoptions: [
        {
          value: "选项1",
          label: "未物理入湖",
          state: "待运行"
        },
        {
          value: "选项2",
          label: "物理入湖中",
          state: "运行中"
        },
        {
          value: "选项3",
          label: "暂停入湖",
          state: "暂停"
        },
        {
          value: "选项4",
          label: "已完成",
        },
      ],
      tableData: [],
      taskNameValue: "",
      databaseValue: "",
      method: "",
      status: "",

      page: 1, //当前第几页
      size: 10, //当前每页页数
      total: 100, //全部数据量

      currOperator: "",
      currOperatorName: "",
      setting: {
        batchRemove: "",
        advancesearch:"" ,
        backups:""
      },

      dataListSelections: [],

      dataListLoading: false,
    };
  },
  components: {
    CheckTaskLog,
    addAccessTaskDialog,
    checkJobLog,
    codeTemplate,
  },
  mounted() {
    this.currOperator = JSON.parse(
        window.sessionStorage.getItem("user")
    ).userId;
    this.currOperatorName = JSON.parse(
        window.sessionStorage.getItem("user")
    ).username;
    this.initData();
    //根据名字查询系统设置中的结果
    this.getRequest(
        "/systemSetting/getSettingInfoByName/?name=" + this.name
    ).then((resp) => {
      if (resp.data) {
        console.log(this.name);
        console.log(resp.data);
        this.setting.batchRemove = resp.data.batchremove;
        this.setting.advancesearch = resp.data.advancesearch;
        // this.setting.backups = resp.data.backups;
        this.size = resp.data.numberperpage;
      }
    });
  },
  methods: {
    UpdateHandle(row) {
      console.log(row);
    },
    currentChange(currentPage) {
      this.page = currentPage;
      this.initData();
    },
    sizeChange(currentSize) {
      this.size = currentSize;
      this.initData();
    },
    initData() {
      this.dataListLoading = true;
      this.getRequest(
          // "/dataAccess/getDataAccessTask/?page=" +
          // this.page +
          // "&size=" +
          // this.size
          "/dataAccess/queryByCondition/?page=" + this.page + "&size=" + this.size +
          "&taskName=" +
          this.taskNameValue +
          "&accessSource=" +
          this.databaseValue +
          "&status=" +
          this.status
      ).then((resp) => {
        if (resp.data) {
          this.tableData = resp.data.data;
          this.total = resp.data.total;
        }
        this.dataListLoading = false;
      });
      this.getRequest(
          "/dataAccess/getDataAccessTaskOverView"
      ).then((resp) => {
        if (resp.data) {
          this.options[0].num = resp.data.taskCount + "个";
          this.options[1].num = resp.data.readyTaskCount + "个";
          this.options[2].num = resp.data.runningTaskCount + "个";
          this.options[3].num = resp.data.pauseTaskCount + "个";
          this.options[4].num = resp.data.endTaskCount + "个";
        }
      });
    },
    addDataAccessTask() {
      this.addTaskDialog = true;
      this.$nextTick(() => {
        this.$refs.addAccessTaskDialog.init();
      });
    },
    startTask(taskId, accessSource) {
      this.$confirm(`确定进行对任务[${taskId}]进行开启操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (accessSource === "influxDB") {

          this.getRequest("/Scheduler/startInfluxJob/?taskId=" + taskId).then(
              (resp) => {
                if (resp) {
                  this.initData();
                }
              }
          );
        }
        if (accessSource === "mysql" || accessSource === "oracle") {

          this.getRequest("/Scheduler/startMysqlJob/?taskId=" + taskId).then((resp) => {
            if (resp) {
              this.initData();
            }
          });
        }
        if(accessSource ==='txt'){
          this.getRequest("/Scheduler/startTxtJob/?taskId=" + taskId).then((resp) => {
            if (resp) {
              this.initData();
            }
          });
        }
        if(accessSource ==='word'){
          this.getRequest("/Scheduler/startWordJob/?taskId=" + taskId).then((resp) => {
            if (resp) {
              this.initData();
            }
          });
        }
        if(accessSource ==='excel'){
          this.getRequest("/Scheduler/startExcelJob/?taskId=" + taskId).then((resp) => {
            if (resp) {
              this.initData();
            }
          });
        }
        if(accessSource ==='传感器'){
          this.getRequest("/Scheduler/startSensorJob/?taskId=" + taskId).then((resp) => {
            if (resp) {
              this.initData();
            }
          });
        }
        if(accessSource ==='第三方'){
          this.getRequest("/Scheduler/startThirdPartyJob/?taskId=" + taskId).then((resp) => {
            if (resp) {
              this.initData();
            }
          });
        }
        if(accessSource ==='opc'){
          this.getRequest("/Scheduler/startOpcJob/?taskId=" + taskId).then((resp) => {
            if (resp) {
              this.initData();
            }
          });
        }
      }).catch(() => {})
    },

    pauseTask(taskId, accessSource) {
      this.$confirm(`确定进行对任务[${taskId}]进行暂停操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (accessSource === "influxDB") {
          this.getRequest("/Scheduler/pauseInfluxJob/?taskId=" + taskId).then(
              (resp) => {
                if (resp) {
                  this.initData();
                }
              }
          );
        }
        if (accessSource === "mysql" || accessSource === "oracle") {
          this.getRequest("/Scheduler/pauseMysqlJob/?taskId=" + taskId).then(
              (resp) => {
                if (resp) {
                  this.initData();
                }
              }
          );
        }
        if(accessSource ==='txt'){
          this.getRequest("/Scheduler/pauseTxtJob/?taskId=" + taskId).then((resp) => {
            if (resp) {
              this.initData();
            }
          });
        }
        if(accessSource ==='word'){
          this.getRequest("/Scheduler/pauseWordJob/?taskId=" + taskId).then((resp) => {
            if (resp) {
              this.initData();
            }
          });
        }
        if(accessSource ==='excel'){
          this.getRequest("/Scheduler/pauseExcelJob/?taskId=" + taskId).then((resp) => {
            if (resp) {
              this.initData();
            }
          });
        }

        if(accessSource ==='opc'){
          this.getRequest("/Scheduler/pauseOpcJob/?taskId=" + taskId).then((resp) => {
            if (resp) {
              this.initData();
            }
          });
        }
      }).catch(() => {})
    },

    recover(taskId, accessSource) {
      this.$confirm(`确定进行对任务[${taskId}]进行恢复操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (accessSource === "influxDB") {
          this.getRequest("/Scheduler/recoverInfluxJob/?taskId=" + taskId).then(
              (resp) => {
                if (resp) {
                  this.initData();
                }
              }
          );
        }
        if (accessSource === "mysql" || accessSource === "oracle") {
          this.getRequest("/Scheduler/recoverMysqlJob/?taskId=" + taskId).then(
              (resp) => {
                if (resp) {
                  this.initData();
                }
              }
          );
        }
        if (accessSource === "传感器") {
          this.getRequest("/Scheduler/recoverSensorJob/?taskId=" + taskId).then(
              (resp) => {
                if (resp) {
                  this.initData();
                }
              }
          );
        }
        if(accessSource ==='第三方'){
          this.getRequest("/Scheduler/recoverThirdPartyJob/?taskId=" + taskId).then((resp) => {
            if (resp) {
              this.initData();
            }
          });
        }

        if(accessSource ==='opc'){
          this.getRequest("/Scheduler/recoverOpcJob/?taskId=" + taskId).then((resp) => {
            if (resp) {
              this.initData();
            }
          });
        }
        if(accessSource ==='txt'){
          this.getRequest("/Scheduler/recoverTxtJob/?taskId=" + taskId).then((resp) => {
            if (resp) {
              this.initData();
            }
          });
        }
        if(accessSource ==='word'){
          this.getRequest("/Scheduler/recoverWordJob/?taskId=" + taskId).then((resp) => {
            if (resp) {
              this.initData();
            }
          });
        }
        if(accessSource ==='excel'){
          this.getRequest("/Scheduler/recoverExcelJob/?taskId=" + taskId).then((resp) => {
            if (resp) {
              this.initData();
            }
          });
        }
      }).catch(() => {})
    },

    deleteJob(taskId, accessSource) {
      this.$confirm(`确定进行对任务[${taskId}]进行初始化操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (accessSource === "influxDB") {
          this.getRequest("/Scheduler/deleteInfluxJob/?taskId=" + taskId).then(
              (resp) => {
                if (resp) {
                  this.initData();
                }
              }
          );
        }
        if (accessSource === "mysql") {
          this.getRequest("/Scheduler/deleteMysqlJob/?taskId=" + taskId).then(
              (resp) => {
                if (resp) {
                  this.initData();
                }
              }
          );
        }
        if(accessSource ==='txt'){
          this.getRequest("/Scheduler/deleteTxtJob/?taskId=" + taskId).then((resp) => {
            if (resp) {
              this.initData();
            }
          });
        }
        if(accessSource ==='word'){
          this.getRequest("/Scheduler/deleteWordJob/?taskId=" + taskId).then((resp) => {
            if (resp) {
              this.initData();
            }
          });
        }
        if(accessSource ==='excel'){
          this.getRequest("/Scheduler/deleteExcelJob/?taskId=" + taskId).then((resp) => {
            if (resp) {
              this.initData();
            }
          });
        }
        if(accessSource ==='opc'){
          this.getRequest("/Scheduler/deleteOpcJob/?taskId=" + taskId).then((resp) => {
            if (resp) {
              this.initData();
            }
          });
        }

      }).catch(() => {})
    },
    //修改
    updateJob(taskId, accessSource, name, remark){
      let sourceType = 1
      if (accessSource == 'mysql' || accessSource == 'sql server' || accessSource == 'oracle' || accessSource == 'influxDB') {
        sourceType = 1
      } else if (accessSource == 'txt' || accessSource == 'word' || accessSource == 'excel') {
        sourceType = 2
      } else if (accessSource == '传感器') {
        sourceType = 3
      } else if (accessSource == '第三方') {
        sourceType = 4
      }else if (accessSource == 'opc') {
        sourceType = 5
      }
      this.$router.push({
        path:'/addDataAccessTask',
        name: "添加数据接入任务",
        query: {
          taskId: taskId,
          sourceType: sourceType,
          taskName: name,
          remark: remark,
          accessSource: accessSource,
          currOperator: this.currOperator,
          flag:1,
          update:1,
        },
        // params: {
        //   taskId: taskId,
        //   taskName: name,
        //   remark: remark,
        //   accessSource: accessSource,
        //   currOperator: this.currOperator,
        //   flag:1,
        //   update:1,
        // },
      })
    },
    //下载
    downLoadJob(taskId, accessSource){
      if(accessSource ==='txt' || accessSource ==='word'||accessSource ==='excel'){
        this.$prompt('请输入监听的文件夹路径', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(({ value }) => {

          let data={
            path:value,
            uid:this.currOperator,
            taskId:taskId
          }
          console.log(data)
          this.postRequest("/addDataAccessTask/writeListenFileConf",data).then((resp) => {

          }).then(()=>{
            this.getRequest("/addDataAccessTask/downloadListenFile").then((resp) => {
              if(resp.data&&resp.data.code===0){
                window.location.href=resp.data.downloadUrl;
              }else{
                this.$message.error('下载失败');
              }
            }).then(()=>{
              this.getRequest("/addDataAccessTask/downloadListenFilePath").then((resp) => {
                if(resp.data&&resp.data.code===0){
                  window.location.href=resp.data.downloadUrl;
                }else{
                  this.$message.error('下载失败');
                }
              }).then(()=>{
              })
            })
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消输入'
          });
        });
      }
    },
    codeTemplate(taskId, accessSource){
      if(accessSource ==='传感器' || accessSource ==='第三方'){
        this.$refs.codeTemplate.open(taskId);
      }
    },
    // 多选
    selectionChangeHandle (val) {
      this.dataListSelections = val
    },
    deleteTask(id) {
      var flag= true;
      var taskIds = id ? [id] : this.dataListSelections.map(item => {
        console.log(item)
        return item.id
      });
      this.$confirm(`确定对[id=${taskIds.join(',')}]进行[${id ? '删除' : '批量删除'}]操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.dataListSelections.forEach(item => {
          if (item.status != "待运行") {
            this.$message({
              type: "warning",
              message: "任务" + item.id + "正在被使用",
            });
            flag = false;
          }
        })
        if (flag == true) {
          this.getRequest("/dataAccess/deleteTask?taskIds=" + taskIds).then(
              (resp) => {
                if (resp.data) {
                  this.initData();
                }
              }
          );
        }
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      });
    },
    queryByCondition() {
      this.getRequest(
          "/dataAccess/queryByCondition/?page=" + this.page + "&size=" + this.size +
          "&taskName=" +
          this.taskNameValue +
          "&accessSource=" +
          this.databaseValue +
          "&status=" +
          this.status
      ).then((resp) => {
        if (resp.data) {
          this.tableData = resp.data.data;
          this.total = resp.data.total;
        }
      });
    },
    logHandle(taskId, taskName) {
      this.logVisible = true
      this.$nextTick(() => {
        this.$refs.checkJobLog.init(taskId, taskName)
      })
    },
    taskLog(taskId, taskName) {
      this.taskLogVisible = true;
      this.$nextTick(() => {
        this.$refs.checkTaskLog.init(taskId, taskName)
      })
    }

    // addDataAccessTask() {
    //   this.$router.push({
    //     path: '/addDataAccessTask'
    //   })
    // }
  },
};
</script>

<style scoped>
	.coalblock_1{
		text-align: center;
		display: inline-block;
		padding: 3px 5px;
	}
</style>
